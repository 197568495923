import React, { useContext, useMemo } from 'react';
import { useMount } from 'react-use';
import { navigate } from '@reach/router';
import Layout from 'components/Layout';
import AuthForm from 'components/AuthForm';
import AuthContext from 'contexts/auth';
import { Container } from 'ui/containers';

const LoginPage = ({ location }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const nextUrl = useMemo(() => location?.state?.nextUrl || '/', [location]);

  const handleLoginSuccess = () => {
    navigate(nextUrl);
  };

  useMount(() => {
    if (isAuthenticated) {
      navigate(nextUrl);
    }
  });

  return (
    <Layout>
      <Container>
        <AuthForm onLoginSuccess={handleLoginSuccess} />
      </Container>
    </Layout>
  );
};

export default LoginPage;
